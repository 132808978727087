<template>
  <div
    class="pl-0 align-center pa-0 truncate-text-holder"
    :class="{
      'd-flex': shouldTrucateText
    }"
  >
    <s-text
      color="grayTextAlt"
      weight="normal"
      size="md"
      ref="truncateText"
      :class="{
        'truncate-text': shouldTrucateText
      }"
    >
      {{ text }}
    </s-text>
    <span
      class="micro-btn"
      :class="{
        'active': !shouldTrucateText
      }"
      v-if="shouldTruncate"
      @click="isTruncated = !isTruncated"
    >
      {{ truncateButtonText }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'TruncateText',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    shouldTrucateText () {
      return (this.shouldTruncate && this.isTruncated)
    },
    truncateButtonText () {
      return this.shouldTrucateText
        ? 'show more'
        : 'show less'
    },
  },
  data () {
    return {
      isTruncated: true,
      shouldTruncate: false,
    }
  },
  mounted () {
    const truncateTextEl = this.$refs.truncateText.$el
    // base on the browser, single text line should be 24px
    // checking if line is greater that the single line height, +6 for browser allowances
    const singleTextLineHeight = 30
    if (truncateTextEl.clientHeight > singleTextLineHeight) this.shouldTruncate = true
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
.truncate-text-holder {
  overflow: hidden;
}
.micro-btn {
  border: $thin-borderline;
  border-radius: $radius-6;
  color: var(--v-grayTextAlt-base);
  padding: 0px 4px;
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  &.active {
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
  }
}
</style>
