var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-0 align-center pa-0 truncate-text-holder",class:{
    'd-flex': _vm.shouldTrucateText
  }},[_c('s-text',{ref:"truncateText",class:{
      'truncate-text': _vm.shouldTrucateText
    },attrs:{"color":"grayTextAlt","weight":"normal","size":"md"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),(_vm.shouldTruncate)?_c('span',{staticClass:"micro-btn",class:{
      'active': !_vm.shouldTrucateText
    },on:{"click":function($event){_vm.isTruncated = !_vm.isTruncated}}},[_vm._v(" "+_vm._s(_vm.truncateButtonText)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }